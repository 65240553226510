import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mpkUserImage',
  standalone: true,
})
export class UserImagePipe implements PipeTransform {
  transform(imageIdentifier: string | null | undefined): string | undefined {
    return imageIdentifier ? `api/m/kernel/users/identity/image/${imageIdentifier}` : undefined;
  }
}
